import React, { useContext } from 'react'
import './header.css'
function Header({ }) {
    return (
        <header>
            <div className='userItems'>
                <h1 className='title'>Alsopss</h1>
                <a href='https://shop.alsopss.com' className='icon'>
                <i class="bi bi-shop"></i>
               </a>
               <a href='https://games.alsopss.com' className='icon'>
                <i class="bi bi-controller"></i>
               </a>
            </div>
        </header>
    )
}

export default Header;